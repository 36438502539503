<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <h1 class="text-center">Change Ad Plan</h1>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div class="card-deck mb-3 text-center" v-if="loading === false">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.regional.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.regional.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The Premium Plan includes the benefits of the Basic Listing (map pin/list on both website and app) plus a scrolling ad on the website which gives upgraded visibility. The Premium advertisement rotates on all search options on desktop. <i>(Please visit www.manestreetmarket.com and click on Horses for Sale or Service Providers to see website ads.)</i>
            </p>          
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="upgrade(plans.regional.code)" :disabled="item.package_name === plans.regional.code">Upgrade</button>
          </div>
        </div>
        <!-- <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.national.name}}</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">${{plans.national.prices.monthly.price}} <small class="text-muted">/ mo</small></h1>
            <p>
              App and Website Advertiser includes benefits of Website Advertiser and Basic Listing plus rotating banner advertisement on app. Ads on web and mobile apps include a hyperlink to advertiser's website. This option provides maximum exposure to the MSM audience. Please contact support@manestreetmarket.com for ad specs before choosing this option. <i>(Please download the Mane Street Market app to see current app banner ads scrolling on the bottom of the screen.)</i>
            </p>         
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="upgrade(plans.national.code)" :disabled="item.package_name === plans.national.code">Upgrade</button>
          </div>
        </div> -->
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.premier.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.premier.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              COMING SOON!
            </p>
          </div>
          <!-- <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="upgrade(plans.premier.code)" :disabled="item.package_name === plans.premier.code">Upgrade</button>
          </div> -->
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import plans from '@/constants/plans';
import axios from "axios";
import {db} from "@/firebase";
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/spin/style/index.css';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ASpin
  },
  data() {
    return {
      loading: false,
      plans: plans,
      item: {}
    }
  },
  async mounted() {
    document.title = 'Change Ad Plan - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadAd();
  },
  methods: {
    async loadAd() {
      this.loading = true;
      try {
        let response = await axios.get('/api/ads/' + this.$route.params.id);
        this.item = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async upgrade(packageName) {
      let plan = this.plans[packageName];
      this.loading = true;
      try {
        await axios.post('/api/change-ad-plan', {
          id: this.$route.params.id,
          package_name: plan.code,
          price_id: plan.prices.monthly.id
        });
        let userProfile = await db.collection('Users').doc(this.$store.state.user.uid).get()
        this.$store.dispatch('setProfile', userProfile.data());
        this.$router.push(`/my-ads?upgrade_completed=1`);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>